<template>
  <div class="yeekeeResultPage">
    <div id="section-content" class="container container-fluid">
      <div class="bar-back d-flex justify-content-between align-items-center">
        <div id="top"></div>
        <router-link :to="`/member/lottery/yeeki/${catalogId}`" class="mr-auto"
          ><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link
        >
        <a v-b-modal.rule-yeekee href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" >กติกา</a>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px);" v-if="getYeekiInfo.status==4 && getYeekiInfo.status != ''">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-exclamation-triangle"></i> {{ getYeekiInfo ? getYeekiInfo.group_name : ''  }} - {{ getYeekiInfo.title }}</h4>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
          <div class="text-center my-4">
            <span style="font-size:100px;line-height:1.2;"><i class="fas fa-spinner fa-spin text-danger"></i></span>
              <h4 class="text-danger" style="font-family:inherit"> ยกเลิกรอบเนื่องจากยอดการยิงเลขมีไม่ถึง 20 ลำดับ </h4>
            </div>
        </div>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 150px) - 50px)" v-if="getYeekiInfo.status != 4 && getYeekiInfo.status != ''">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-award"></i> ผลรางวัล</h4>
          </div>
        </div>

        <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-1">
          <h6><i class="fas fa-trophy"></i> {{ getYeekiInfo ? getYeekiInfo.group_name : '' }}  <span class="badge badge-dark"> {{ getYeekiInfo.title }}</span></h6>
          <div class="card border-dark text-center mb-2">
              <div class="card-header text-danger p-1">
                  {{ getYeekiInfo ? getYeekiInfo.group_name : '' }} - {{ getYeekiInfo.title }}                        </div>
              <div class="card-body p-0">

                  <small class="text-secondary">- ปิดรับการทายผลตัวเลขออกรางวัล -</small>

                  <div class="card text-center w-100 rounded-0 border-right-0 border-bottom-0 border-left-0 m-0">
                      <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                          ผลรางวัล                                </div>
                      <div class="card-body border-bottom p-0">
                          <h3 class="number text-center">
                              {{ reward.prefix }}<span class="number text-success">{{ reward.twoUp }}</span><span class="number text-danger">{{ reward.treeUp }}</span>
                          </h3>
                      </div>
                  </div>

                  <div class="d-flex flex-row">

                      <div class="card text-center w-50 border-card-right m-0" >
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                              3ตัวบน                                    </div>
                          <div class="card-body p-0">
                              <h3 class="card-text mb-1">{{ reward.treeUp }}</h3>
                          </div>
                      </div>

                      <div class="card text-center w-50 border-card-right m-0">
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                              2ตัวล่าง                                    </div>
                          <div class="card-body p-0">
                              <h3 class="card-text mb-1">{{ reward.twoUp }}</h3>
                          </div>
                      </div>

                  </div>

                  <div class="card text-center w-100 rounded-0 border-right-0 border-bottom-0 border-left-0 m-0">
                      <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                          ผลรวมยิงเลข                                </div>
                      <div class="card-body p-0">
                          <h5 class="number text-center">
                              {{ listPush.sum }}                                    </h5>
                      </div>
                  </div>

                  <div class="card text-center w-100 rounded-0 border-right-0 border-bottom-0 border-left-0 m-0">
                      <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                          เลขแถวที่ 16                                </div>
                      <div class="card-body border-bottom p-0">
                          <h5 class="number text-primary text-center">
                              {{ listPush.description.recond_16.num }}                                    </h5>
                      </div>
                  </div>

                  <div class="d-flex flex-row">

                      <div class="card text-center w-50 border-card-right m-0">
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0 d-flex flex-column flex-sm-column flex-md-row align-items-center justify-content-center w-100" style="line-height:1.2;">
                              <span class="d-block mr-1">สมาชิกยิงเลขได้</span><u>อันดับ 1</u>
                          </div>
                          <div class="card-body p-0">
                              <h6 class="card-text mb-2">{{ getNameDecode(listPush.description.recond_1.name) }}  </h6>
                          </div>
                      </div>

                      <div class="card text-center w-50 border-card-right m-0">
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0 d-flex flex-column flex-sm-column flex-md-row align-items-center justify-content-center w-100" style="line-height:1.2;">
                              <span class="d-block mr-1">สมาชิกยิงเลขได้</span><u>อันดับ 16</u>
                          </div>
                          <div class="card-body p-0">
                              <h6 class="card-text mb-2">{{ getNameDecode(listPush.description.recond_16.name) }}  </h6>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>
      <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5">
        <div class="d-flex flex-column">
            <h5 class="text-center text-success"><i class="fas fa-th-list"></i> </h5>
              <template v-for="(item, index) in listPush.lists" >
                <div class="d-flex flex-row justify-content-between align-items-stretch mb-1" :key="index" v-if="isWin(index)">
                  <div class="bg-success text-white text-center rounded py-2 px-1 w-25 d-flex flex-column justify-content-around">
                      <span class="badge badge-pill badge-light text-success">อันดับ {{ ((listPush.page - 1) * listPush.count) + index + 1 }}</span>
                      <span class="mb-0" style="font-size:145%">{{ item.num }}</span>
                  </div>
                  <div class="border border-success bg-light rounded p-2 ml-1 flex-fill d-flex flex-column justify-content-around">
                      <div><span class="badge badge-pill badge-light text-success"><i class="fas fa-user-circle"></i>
                              ผู้ส่งเลข</span> {{ getNameDecode(item.name) }}</div>
                      <hr class="my-1">
                      <div><span class="badge badge-pill badge-light text-success"><i class="fas fa-calendar-check"></i>
                              เมื่อ</span> {{ item.date }}</div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-between align-items-stretch mb-1" :key="index" v-else>
                  <div class="bg-secondary text-white text-center rounded py-2 px-1 w-25 d-flex flex-column justify-content-around">
                      <span class="badge badge-pill badge-light ">อันดับ {{ ((listPush.page - 1) * listPush.count) + index + 1 }}</span>
                      <span class="mb-0" style="font-size:145%">{{ item.num }}</span>
                  </div>
                  <div class="border border-success bg-light rounded p-2 ml-1 flex-fill d-flex flex-column justify-content-around">
                      <div><span class="badge badge-pill badge-light "><i class="fas fa-user-circle"></i>
                              ผู้ส่งเลข</span> {{ getNameDecode(item.name) }}</div>
                      <hr class="my-1">
                      <div><span class="badge badge-pill badge-light "><i class="fas fa-calendar-check"></i>
                              เมื่อ</span> {{ item.date }}</div>
                  </div>
                </div>
              </template>
               <b-pagination class="pagination pagination-sm justify-content-center mt-2"
               style="z-index: 0;"
                      v-model="page"
                      :total-rows="listPush.count * listPush.max_page"
                      :per-page="listPush.count"
                    ></b-pagination>
        </div>
    </div>
      </div>
    </div>
    <app-rule-yeekee-modal />
  </div>
</template>

<script>
import ruleYeekeeModal from '@/components/modals/RuleYeekeeModal'
import { mapGetters, mapActions } from 'vuex'
import { getRuleByID, listPushNumberYeekee } from '@/services'
export default {
  components: {
    'app-rule-yeekee-modal': ruleYeekeeModal
  },
  async created () {
    this.setLoading(true)
    this.roundID = this.$route.params.id
    this.catalogId = this.$route.params.catalog
    if (this.$route.params.page > 0) {
      this.page = this.$route.params.page
    } else {
      this.page = 1
    }
    try {
      await this.getYeekiInfomationByRoundId(this.$route.params.id)
      if (Number(this.getYeekiInfo.status) === 0 || Number(this.getYeekiInfo.status) === 1) {
        this.$router.replace(`/member/lottery/yeeki-fire/${this.catalogId}/${this.roundID}`)
      } else if (Number(this.getYeekiInfo.status) === 3) {
        await this.fetchResultLotto(this.$route.params.id)
      }
      const resp = await getRuleByID(this.roundID)
      if (resp.status_code === 'SUCCESS') {
        this.pathImage = resp.data.pathImage
      }
      this.setLoading(true)
      await this.listPushNumber()
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  data: () => {
    return {
      page: 1,
      title: '',
      info: {},
      roundID: '',
      catalogId: '',
      pathImage: '',
      listPush: {
        count: 0,
        description: {
          recond_1: { name: '', num: '' },
          recond_16: { name: '', num: '' }
        },
        index_win: [],
        lists: [],
        listsIsWin: [],
        max_page: 0,
        page: 1,
        sum: 0
      },
      reward: {
        prefix: 'XXX',
        twoUp: 'XX',
        treeUp: 'XXX'
      }
    }
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getYeekiRound', 'getYeekiCatalog', 'getYeekiInfomationByRoundId']),
    async listPushNumber () {
      const resp = await listPushNumberYeekee({ roundID: parseInt(this.roundID), page: parseInt(this.page) })
      if (resp.status_code === 'SUCCESS') {
        this.listPush = resp.data
        const sum = (this.listPush.sum - this.listPush.description.recond_16.num) + ''
        this.reward.prefix = sum.substring(0, sum.length - 5)
        this.reward.treeUp = sum.substring(sum.length - 3, sum.length)
        this.reward.twoUp = sum.substring(sum.length - 5, sum.length - 3)
      }
    },
    getNameDecode (name) {
      /* Format XX***XX */
      return name.substring(0, 2) + '***' + name.substring(name.length - 2, name.length)
    },
    isWin (index) {
      return this.listPush.index_win.includes(((this.listPush.page - 1) * this.listPush.count) + index)
    },
    getPreviousClass (index) {
      return (this.page === 1) ? 'disabled' : ''
    }
  },
  computed: {
    ...mapGetters(['getResultLotto', 'getYeekiCatalogList', 'getYeekiInfo']),
    rows () {
      return this.items.length
    }
  },
  watch: {
    page: function (value) {
      this.listPushNumber()
    }
  }
}
</script>

<style>
</style>
